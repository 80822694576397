import React from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from "../components/Common/PageBanner"
import Footer from "../components/App/Footer"
import CaseStudiesDetailsContent from "../components/CaseStudiesDetails/CaseStudiesDetailsContent"
import RelatedProjects from "../components/CaseStudiesDetails/RelatedProjects"

const CaseDetails = () => {
  const pageTitle = "Case Study - Instaloop"
  const activePageText = "Case Study Instaloop"

  // Main Content Area
  const type =
    "1 Customer Facing Website + 1 Chrome Extension";

  const title = "Training, Recruitement and Staffing solutions for a Security Guard Company"
  const body1 = "The Challenge - Create a low cost, high return training system."
  const body2 = "The Solution - We decided to build a Wordpress project linked to a firebase project. This way SEO & custom training functionality can be acheived quickly and at low cost. Project was built with constant client feedback every week, and result was over expectations."
  const body3 = "Project was finished within 1.5 months. Client saw a ROI of >300% within 10months of starting marketing. Currently project is undergoing a Version 2 update."
  const results = "The Result - A working training system that now generates passive income for the owner, and generates passive stream of new security guards to be used for their client events & properties"


  // Side bar content area
  const clientName = "Hydra Security"
  const clientLink = "https://hycrasecurity.ca"
  const clientName2 = ""
  const clientLink2 = ""
  const location = "Unit 42, 2130 North Park Drive, Brampton, L6S 5M4"
  const technologies = "Front-end: HTML, CSS, JS, Wordpress.  Back-end: Firebase"
  const completionDate = "2022 Sept 30"

  const products = [{ hrefName: "Hydra Security - Wordpress Website", href: "https://hydrasecurity.ca" },
  { hrefName: "Hydra Security React Training Portal", href: "https://hydra-securityprod.web.app/login" },
  ]

  return (
    <Layout pageTitle={pageTitle}>
      <Navbar />
      <PageBanner
        pageTitle={pageTitle}
        homePageText="Home"
        homePageUrl="/"
        activePageText={activePageText}
      />
      <CaseStudiesDetailsContent
        type={type}
        title={title}
        body1={body1}
        body2={body2}
        body3={body3}
        results={results}
        clientName={clientName}
        clientLink={clientLink}
        clientName2={clientName2}
        clientLink2={clientLink2}
        location={location}
        technologies={technologies}
        completionDate={completionDate}
        products={products}
      />
      {/* <RelatedProjects /> */}
      <Footer />
    </Layout>
  )
}

export default CaseDetails
